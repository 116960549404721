import { motion } from 'framer-motion';
import React , {useEffect, useState} from 'react';
import { paragraphAnimation } from '../../utils/config';
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import inIcon from '../../assets/icons/in_icon.png';
import outIcon from '../../assets/icons/out_icon.png';
import axios from 'axios';


import { useSelector } from 'react-redux';
import './history.scss';
import { toast } from 'react-toastify';
import WalletConnectProvider from '@walletconnect/web3-provider'
import utils from '@okwallet/extension/src/utils';

const claimContractAbi = require('../../helper/presale.json')
const alphaAbi = require('../../helper/usdt.json')

const vestingContractAbi = require('../../helper/vesting.json')

let web3Modal;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
  },
}

if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
  })

  console.log(web3Modal)
}


export default function Mint() {

  const web3provider = useSelector(state => state.web3Provider);
  const address = useSelector(state => state.address);
  //const address = "0x047862412AF18dA4c549549630887dbA1AF6C0F2"
  const [amount, setAmount] = React.useState(500);
  const [depositStatus, setDepositStatus] = useState("disabled");
  const [currentCap, setCurrentCap] = useState(0)
  const [closeWindow, setCloseWindow] = useState("")
  const [maxCap, setMaxCap] = useState(0)
  const [balance, setBalance] = useState(0)
  const [totalUsdtDeposited, setTotalUsdtDeposited] = useState('0');
  const [transactions, setTransactions] = useState([]);
  const tokenAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7"

  useEffect(() => {
    const fetchDepositStatus = async () => {
      try {
        await fetchTransactionHistory(); // Fetch transaction history
      } catch (error) {
        console.error('Error fetching deposit status:', error);
      }
    };

    if (web3provider) {
      fetchDepositStatus();
    }
  }, [web3provider]);

  const openEtherscan = (transactionHash) => {
    const etherscanUrl = `https://etherscan.io/tx/${transactionHash}`;
    window.open(etherscanUrl, '_blank');
  };


  const fetchTransactionHistory = async () => {
    try {
      const response = await axios.get(`https://api.alphashards.com/api/transaction-history/0`);
      console.log(response)
      const transactionHistoryData = response.data;

      if (!Array.isArray(transactionHistoryData)) {
        console.error('Transaction history data is not an array');
        // Optionally, you can throw an error or handle it in another way
        return;
      }

      setTransactions(transactionHistoryData);
    } catch (error) {
      console.error('Error fetching transaction history:', error);
    }
  };


  const formatTransactionDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: '2-digit', hour: 'numeric', minute: '2-digit', hour12: true };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const formatUserAddress = (address) => {
    if (!address || address.length < 7) {
      return address; // Return the original address if it's too short
    }

    const formattedAddress = `${address.slice(0, 4)}...${address.slice(-5)}`;
    return formattedAddress;
  };

  const formatTransactionHash = (hash) => {
    if (!hash || hash.length < 16) {
      return hash; // Return the original hash if it's too short
    }

    const formattedHash = `${hash.slice(0, 10)}...${hash.slice(-5)}`;
    return formattedHash;
  };


  return (
    <>
      <motion.section {...paragraphAnimation} id="deposit" className="deposit">
        <div className="deposit__content">
          {/* ... (your existing UI elements) */}

          {/* Transaction History */}
          <div className="transaction-history">
            <h2>Transfer History</h2>
            <table>
              <tbody>
                {transactions.map((transaction) => (
                  <tr 
                  key={transaction.transaction_hash}
                  onClick={() => openEtherscan(transaction.transaction_hash)}
                  style={{ cursor: 'pointer' }}
                  >
                    <td>{formatTransactionDate(transaction.created_at)}</td>
                    <td>{formatUserAddress(address)}</td>
                    <td>
                    { transaction.transaction_type == 'deposit' &&
                        <img src={outIcon} alt="USDT Logo" style={{ width: '15px', marginLeft: '5px' }} />                   
                    }
                    { transaction.transaction_type == 'disbursement' &&
                        <img src={inIcon} alt="USDT Logo" style={{ width: '15px', marginLeft: '5px' }} />                   
                    }
                    </td>

                    <td>{formatUserAddress('0x27497d05EE1b152486b126d5faCFc771d4f31f86')}</td>

                    <td style={{color:'green'}}>{formatTransactionHash(transaction.transaction_hash)}</td>
                    <td>{transaction.amount} <img src="https://cryptologos.cc/logos/tether-usdt-logo.svg?v=029" alt="USDT Logo" style={{ width: '15px', marginLeft: '5px' }} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </motion.section>
    </>
  );
}
